// @ts-nocheck: converted from JS

import { Children, cloneElement, createContext, FC, useContext, useState } from 'react';
import './Accordion.scss';

const AccordionContext = createContext();

interface AccordionProps {
  style?: any;
  className?: any;
  children?: any;
  allowMultipleExpanded?: any;
  outerState?: any;
}

export const Accordion: FC<AccordionProps> = ({
  style,
  className,
  children,
  allowMultipleExpanded,
  outerState = false,
}) => {
  const innerState = Children.map(children, (c) => c.props.isOpen);
  let state = useState(innerState);
  if (outerState) {
    state = outerState;
  }

  return (
    <div style={style} className={`accordion ${className}`}>
      <AccordionContext.Provider value={[...state, allowMultipleExpanded]}>
        {Children.map(children, (child, index) => cloneElement(child, { index }))}
      </AccordionContext.Provider>
    </div>
  );
};

Accordion.defaultProps = {
  allowMultipleExpanded: false,
  outerState: false,
};

interface AccordionItemProps {
  index?: any;
  style?: any;
  className?: any;
  children?: any;
  disabled?: any;
}

export const AccordionItem: FC<AccordionItemProps> = ({ index, style, className, children, disabled = false }) => {
  return (
    <div style={style} className={`accordion__item ${className} ${disabled ? '--disabled' : ''}`}>
      {Children.map(children, (child) => cloneElement(child, { index, disabled }))}
    </div>
  );
};

interface AccordionHeaderProps {
  index?: any;
  disabled?: any;
  style?: any;
  className?: any;
  children?: any;
}

export const AccordionHeader: FC<AccordionHeaderProps> = ({ index, disabled, style, className, children }) => {
  const [openItems, setOpenItems, allowMultipleExpanded] = useContext(AccordionContext);

  const openItem = (openItems, setOpenItems, index, value = true, allowMultipleExpanded) => {
    const tempOpenItems = allowMultipleExpanded ? [...openItems] : [...openItems].map(() => false);

    tempOpenItems[index] = value;
    return setOpenItems(tempOpenItems);
  };

  return (
    <div
      style={style}
      className={`accordion__header ${className}`}
      aria-expanded={openItems[index]}
      data-test-component="AccordionHeader"
      data-test-element="container"
      onClick={() => !disabled && openItem(openItems, setOpenItems, index, !openItems[index], allowMultipleExpanded)}
    >
      {children}
    </div>
  );
};

interface AccordionPanelProps {
  index?: any;
  style?: any;
  className?: any;
  children?: any;
}

export const AccordionPanel: FC<AccordionPanelProps> = ({ index, style, className, children }) => {
  const [openItems] = useContext(AccordionContext);

  return openItems[index] ? (
    <div
      style={style}
      className={`accordion__panel ${className}`}
      data-test-component="AccordionPanel"
      data-test-element="container"
    >
      {children}
    </div>
  ) : (
    <></>
  );
};
