import Loading from '@/components/Loading';
import Measurements from '@/components/Subjects/Show/Measurements';
import FormComponent from '@/components/Subjects/Show/Measurements/Form/FormComponent';
import Deceased from '@/components/Subjects/Show/Notifications/Types/Deceased';
import Banner from '@/components/UI/Banner';
import GroupLabel from '@/components/UI/GroupLabel';
import { checkIsDeceased, getCalculationsAndMeasurements, hasOwnProperty } from '@/helpers';
import { _isEmpty, _isNotEmpty, _notNil } from '@/littledash';
import type { AnimalAlertV1 } from '@/model/Alert.model';
import type { Animal, DeceasedAnimal } from '@/model/Animal.model';
import type { Measurement } from '@/model/Measurement.model';
import type { Study } from '@/model/Study.model';
import type { TreatmentGroup } from '@/model/TreatmentGroup.model';
import { useFetchCollection } from '@/support/Hooks/fetch';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { Alert, AlertUpdateEventHandler } from '@/utils/alerts/Alert';
import { AlertService, AlertUpdateEvent } from '@/utils/alerts/useAlert';
import React, { Reducer, useEffect, useMemo, useReducer, useState } from 'react';
import type { WorkflowModel, WorkflowModelChild } from '../Workflow.model';
import styles from './MeasurmentsView.module.scss';
import { WorkflowNoDataCard } from './WorkflowNoDataCard';
import { useSelector } from 'react-redux';
import { State } from '@/model/State.model.ts';

type AlertState = Record<AnimalAlertV1['id'], AnimalAlertV1>;
type AlertAction = { type: 'alert-update'; data: Array<AnimalAlertV1> };
type AlertReducer = Reducer<AlertState, AlertAction>;

const alertReducer: AlertReducer = (prevState, action) => {
  switch (action.type) {
    case 'alert-update': {
      return action.data.reduce((acc, alert) => ({ ...acc, [alert.id]: { ...(acc?.[alert.id] ?? {}), ...alert } }), {
        ...prevState,
      });
    }
    default: {
      return prevState;
    }
  }
};

const alertSettings = {
  displayHeader: false,
  displayDeferControl: false,
  displayTriggeredBy: false,
};

interface MeasurementViewProps {
  canWrite: boolean;
  closeModal: () => void;
  dispatch: React.Dispatch<unknown>;
  fetchAnimalDataByType: (subject: Animal, type: string) => void;
  measured_at: string;
  measurements: Array<Measurement>;
  onSave: () => void;
  setSubject: React.Dispatch<React.SetStateAction<object>>;
  showGroups: boolean;
  study: Study;
  collections: Array<{ id: string; name: string }>;
  subject: Animal;
  updateChecklist: (type: unknown, id: unknown) => React.Dispatch<unknown>;
  workflow: WorkflowModel;
  fetchSubjects: () => Promise<void>;
  setLoading?: (loading: boolean) => void;
  setUnsavedMeasurements: (unsaved: boolean) => void;
}

const MeasurementView: React.FC<MeasurementViewProps> = ({
  study,
  subject,
  collections,
  measurements,
  fetchAnimalDataByType,
  closeModal,
  workflow,
  showGroups,
  measured_at,
  updateChecklist,
  onSave,
  canWrite,
  dispatch,
  fetchSubjects,
  setLoading,
  setUnsavedMeasurements,
}) => {
  const [alertState, alertStateDispatch] = useReducer<AlertReducer>(alertReducer, {});
  const [animalLoading, setAnimalLoading] = useState(false);

  const workflowMeasurementAudibleNotification = useSelector(
    (state: State) => state?.team?.features?.workflow_measurement_audible_notification ?? false
  );
  const playMeasurementNoise = workflowMeasurementAudibleNotification && (workflow.soundOnMeasurementRecord ?? true);
  const alerts = Object.values(alertState);
  const cageName = useMemo(() => collections?.find(({ id }) => id === subject?.cage_id)?.name, [collections, subject]);
  const isDeceased = useMemo(() => checkIsDeceased(subject), [subject]);
  const isEnabled = canWrite && !isDeceased;

  const updateAlerts = (updatedAlerts: Array<AnimalAlertV1>) => {
    const data = updatedAlerts?.filter(({ subject_id }) => subject.id === subject_id) ?? [];
    if (_isNotEmpty(data)) {
      alertStateDispatch({ type: 'alert-update', data });
      dispatch({ type: 'updateAlerts', data });
    }
  };
  const { collection: animalAlerts, collectionLoading: animalAlertsLoading } = useFetchCollection<AnimalAlertV1>({
    collectionType: 'animalAlerts',
    params: { studyId: study.id, id: subject.id },
    queryParams: { resolved: false },
  });

  const metrics = workflow.children?.filter((w: WorkflowModelChild) => w.value).map((w) => w.name) ?? [];

  const {
    collection: measurement,
    collectionLoading: measurementLoading,
    fetchCollection: refetchMeasurement,
    collectionUpdating: measurementUpdating,
    collectionError: measurementError,
  } = useFetchCollection<Measurement>({
    collectionType: 'measurements',
    params: { id: subject.id },
    queryParams: { measured_at },
  });
  const todaysMeasurement = useMemo(() => {
    return measurement?.[0];
  }, [measurement]);

  useEffect(() => {
    if (_notNil(animalAlerts)) {
      updateAlerts(animalAlerts);
    }
  }, [animalAlerts]);

  useEffect(() => {
    const alertUpdateHandler = (event: AlertUpdateEvent) => updateAlerts(event.detail);
    AlertService.addEventListener('alert-resolved', alertUpdateHandler, { passive: true });
    AlertService.addEventListener('alert-deferred', alertUpdateHandler, { passive: true });
    return () => {
      AlertService.removeEventListener('alert-resolved', alertUpdateHandler);
      AlertService.removeEventListener('alert-deferred', alertUpdateHandler);
    };
  }, []);

  const handleMeasurementSubmit = (response: any, edited: boolean) => {
    const triggeredAlerts: Array<AnimalAlertV1> = response?.subject_alerts ?? [];
    if (workflow.onSave !== 'next-subject') {
      fetchAnimalDataByType(subject, 'measurements');
    }
    if (_isNotEmpty(triggeredAlerts)) {
      updateAlerts(triggeredAlerts);
    }
    if (edited) {
      fetchSubjects();
    }
    closeModal();
    refetchMeasurement();
    // If there are no critical alerts continue workflow
    if (!triggeredAlerts.some(({ notification }) => notification === 'critical')) {
      onSave();
      updateChecklist('measurement', subject.id);
      closeModal();
    } else {
      fetchAnimal();
    }
  };

  let studyGroup: TreatmentGroup | undefined;
  if (showGroups && !_isEmpty(study.study_groups) && hasOwnProperty(subject, 'study_group_id')) {
    studyGroup = study.study_groups.find((studyGroup: TreatmentGroup) => studyGroup.id === subject.study_group_id);
  }

  const handleDeceasedCallback = () => {
    closeModal();
    fetchAnimal();
  };

  const fetchAnimal = async () => {
    if (_notNil(setLoading)) {
      setLoading(true);
    } else {
      setAnimalLoading(true);
    }
    const {
      data: { data },
    } = await Http.get(
      apiRoute(
        'studies.animal.show',
        {
          id: subject.id,
          studyId: study.id,
        },
        { include: 'terminated_at_data' }
      )
    );
    dispatch({
      type: 'updateSubject',
      data: { ...data, study_api_id: subject.study_api_id },
    });
    if (_notNil(setLoading)) {
      setLoading(false);
    } else {
      setAnimalLoading(false);
    }
  };

  const calculationMap = useMemo(
    () => getCalculationsAndMeasurements(study?.settings?.calculations),
    [study?.settings?.calculations]
  );

  const handleAlertUpdate: AlertUpdateEventHandler = async (updateAction) => {
    switch (updateAction.type) {
      case 'resolved':
        AlertService.alertResolved({ alerts: [updateAction.data], dismiss: false });
        updateAlerts([updateAction.data]);
        break;
    }
  };

  if (animalLoading || animalAlertsLoading || measurementLoading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-wrap justify-between items-stetch h-100" data-testid="workflow-measurement-pane">
      <div className="w-50 pv2 ph3 br b--moon-gray bg-white">
        {isDeceased && (
          <Banner info className="mw6 mt3 mb3" dismiss={false}>
            <Deceased subject={subject as DeceasedAnimal} study={study} handleCallback={handleDeceasedCallback} />
          </Banner>
        )}
        {_isNotEmpty(alerts) && (
          <div className={`flex flex-column g3 ${styles.alertsContainer}`}>
            {alerts.map((alert: AnimalAlertV1) => (
              <Alert
                key={alert.id}
                alert={alert}
                calculation={calculationMap.get(alert.calculation)}
                studyId={subject.study_id}
                settings={alertSettings}
                onUpdate={handleAlertUpdate}
              />
            ))}
          </div>
        )}
        {isEnabled && _notNil(measurement) && (
          <div data-testid="workflow-measurement-form">
            <FormComponent
              subject={subject}
              studyId={study.api_id}
              cageName={cageName ?? ''}
              preset={study.settings}
              onMeasurementSubmit={handleMeasurementSubmit}
              refetchAnimal={fetchAnimal}
              editing={false}
              todaysMeasurement={todaysMeasurement}
              disabled={measurementLoading || !!measurementError || measurementUpdating}
              percentChange={workflow?.percentChange}
              playMeasurementNoise={playMeasurementNoise}
              measurements={measurements}
              settings={{
                measured_at,
                measuring: metrics,
              }}
              assignAltId={workflow.assignAltId}
              altIdToAssign={workflow.altIdToAssign}
              setUnsavedMeasurements={setUnsavedMeasurements}
              cursorPosition={workflow.cursorPosition}
            />
          </div>
        )}
      </div>
      <div className="w-50 bg-white" data-testid="workflow-measurement-sidebar">
        {_notNil(studyGroup) && (
          <div className="pa3 mw6 pb0">
            <GroupLabel group={studyGroup} />
          </div>
        )}
        {measurements.length ? (
          <Measurements
            subject={subject}
            study={study}
            measurements={measurements}
            onMeasurementSubmit={handleMeasurementSubmit}
            fromWorkflow={true}
          />
        ) : (
          WorkflowNoDataCard
        )}
      </div>
    </div>
  );
};

export default MeasurementView;
