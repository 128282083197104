// @ts-nocheck: converted from JS

import { metadataTypeIgnoredFilters } from '@/components/Dashboard/Dashboard.utils';
import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import Link from '@/components/UI/Link';
import SelectDropDown from '@/components/UI/SelectDropDown';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList';
import { FilterTypes as filterType } from '@/constants/FilterTypes';
import { fetchMetadataColumns, successToast } from '@/helpers';
import { _isEmpty, _isNotEmpty, _notNil } from '@/littledash';
import { ID } from '@/model/Common.model.ts';
import { web as webRoute } from '@/support/route';
import { sortAscByProperty } from '@/utils/array';
import { modalAction } from '@/utils/modal';
import { filterOptionsFromApi } from '../Colony.utils';

export interface CageFiltersEntity {
  fields: Array<{
    name: string;
    value: string;
    options: Array<Record<string, number>>;
  }>;
  metadata: Array<CageMetadataFilter>;
}

export interface CageMetadataFilter {
  id: ID;
  name: string;
  slug: string;
}

export const colonyCagesBulkActions = (
  {
    useTableProps: {
      selectedFlatRows,
      apiTable: { fetchTableData },
    },
  },
  handleDelete,
  dispatch,
  history
) => {
  const { openModal, closeModal } = modalAction(dispatch);
  const selectedTableRows = selectedFlatRows.map(({ original }) => original).sort(sortAscByProperty('id'));
  const callback = async (text) => {
    closeModal();
    successToast(text);
    fetchTableData();
  };
  return [
    {
      name: 'Manage cages',
      key: 'manage_cages',
      ...validateManageCagesSelection(selectedTableRows),
      action: () => {
        sessionStorage.setItem('selectedColonyCages', JSON.stringify(selectedTableRows));
        history.push(webRoute('colony.manage_cages'));
      },
    },
    {
      name: 'Edit cage details',
      key: 'edit_details',
      ...validateManageCagesSelection(selectedTableRows),
      action: () => {
        sessionStorage.setItem('selectedColonyCages', JSON.stringify(selectedTableRows));
        history.push(webRoute('colony.edit.cages'));
      },
    },
    {
      name: 'Delete',
      ...validateManageCagesSelection(selectedTableRows),
      action: () =>
        openModal('CONFIRM_DELETE_COLLECTIONS', {
          subject: selectedTableRows,
          onClick: () => handleDelete(selectedTableRows, callback),
        }),
      className: 'red',
    },
  ];
};

export const validateManageCagesSelection = (selectedCages = [], limit = 300) => {
  const defaultResult = {
    disabled: false,
    tooltip: '',
  };

  if (_isEmpty(selectedCages)) {
    return defaultResult;
  }

  const allowedAccessPermissions = ['write'];

  if (selectedCages.length > limit) {
    return {
      ...defaultResult,
      disabled: true,
      tooltip: `You can only manage ${limit} cages a time`,
    };
  }

  const studyLinkData = selectedCages[0]['study_link']?.data;
  const cagesAssignedToSameStudy = selectedCages.every((cage) => cage['study_link']?.data?.id === studyLinkData.id);

  if (!cagesAssignedToSameStudy) {
    return {
      ...defaultResult,
      disabled: true,
      tooltip: 'All cages must be assigned to the same study',
    };
  }

  if (_notNil(studyLinkData?.access) && !allowedAccessPermissions.includes(studyLinkData.access)) {
    return {
      ...defaultResult,
      disabled: true,
      tooltip: 'You must have at least write access to the study to manage cages',
    };
  }

  return defaultResult;
};

export const getCellActions = (data, history, dispatch, storeDispatch, handleRemoveCageFromStudy) =>
  data.map(({ id, catalog, operations, study_id: studyID, study_link: studyLink, name, counts: { animals } }) => {
    const canManageCage = _notNil(studyLink?.data?.access) && studyLink.data.access === 'write';
    const cannotMoveCageReason = operations?.move?.reason;
    return [
      {
        name: 'Add animals',
        disabled: !canManageCage || animals >= 10,
        cage: catalog,
        action: () => history.push(webRoute('colony.add.animals', { id })),
      },
      {
        name: 'Remove cage from study',
        disabled: !canManageCage || !studyID || !!cannotMoveCageReason,
        tooltip: cannotMoveCageReason || '',
        cage: catalog,
        action: () =>
          storeDispatch({
            type: 'OPEN_MODAL',
            modal: 'REMOVE_CAGE_FROM_STUDY',
            props: {
              onClick: () => handleRemoveCageFromStudy(id, name),
            },
          }),
      },
      {
        name: 'Print label',
        disabled: false,
        cage: catalog,
        action: () => dispatch({ type: 'SET_CAGE_ID_TO_PRINT', data: id }),
      },
    ];
  });

export const generateColumns = ({ metadata }, columns, actions, addToStudy) => {
  let result = [
    {
      id: 'id',
      accessor: 'id', // to become `number`,
      isVisible: false,
      lockVisibility: true,
    },
    {
      id: 'catalog',
      Header: 'Cage No.',
      accessor: 'catalog', // to become `number`,
      sortBy: 'catalog',
      isVisible: columns?.catalog ?? false,
    },
    {
      id: 'name',
      Header: 'Name',
      sortBy: 'name',
      accessor: 'name',
      isVisible: columns?.name ?? false,
    },
    {
      id: 'population',
      Header: 'Population',
      sortDisabled: true,
      accessor: 'counts.animals',
      isVisible: columns?.population ?? false,
    },
  ];

  if (!addToStudy) {
    result = [
      ...result,
      {
        id: 'study',
        Header: 'Study',
        accessor: 'study_id',
        sortBy: 'study',
        isVisible: columns?.study ?? false,
        Cell: ({ row: { original } }) => {
          const studyData = original['study_link']?.data;
          const studyName = studyData?.name ?? '';
          return studyData?.access ? (
            <Link className="link blue" to={webRoute('studies.show', { id: studyData.id })}>
              {studyName}
            </Link>
          ) : (
            studyName
          );
        },
      },
    ];
  }

  result = [
    ...result,
    {
      id: 'date',
      Header: 'Created',
      accessor: 'created_at',
      sortBy: 'date',
      isVisible: columns?.date ?? false,
      Cell: ({ row: { original } }) => <DateTimeRenderer value={original.created_at} />,
    },
    ...fetchMetadataColumns({ metadata, columns }),
  ];

  if (!addToStudy) {
    result = [
      ...result,
      {
        id: 'actions',
        lockVisibility: true,
        sortDisabled: true,
        accessor: 'actions',
        isVisible: true,
        Cell: ({ cell }) => {
          const cellActions = actions?.[cell.row.index];
          return !_isEmpty(cellActions) ? (
            <span className="cellActions" style={{ textAlign: 'right' }}>
              <SelectDropDown
                title="More options"
                iconOnly
                className="hover-near-black"
                alignMenu="right"
                portalProps={{
                  width: 200,
                }}
              >
                <ActionList actions={cellActions} />
              </SelectDropDown>
            </span>
          ) : (
            ''
          );
        },
      },
    ];
  }

  return result;
};

export const generateMetadataFilterOptions = (metadata) => {
  const result = [];
  metadata?.forEach(({ field_type, id, name }) => {
    if (!metadataTypeIgnoredFilters.includes(field_type)) {
      result.push({
        value: id,
        name,
        operations: [
          {
            name: 'contains',
            value: field_type === 'date' ? filterType.eq_date : filterType.contains,
          },
          {
            name: 'does not contain',
            value: field_type === 'date' ? filterType.not_eq_date : filterType.not_contains,
          },
        ],
      });
    }
  });
  return result;
};

export const generateFilterOptions = (filters: CageFiltersEntity, addToStudy) => {
  const result = [
    {
      value: 'animals.count',
      name: 'Population',
      operations: [
        {
          name: 'greater than',
          value: filterType.gt,
        },
        {
          name: 'less than',
          value: filterType.lt,
        },
        {
          name: 'empty',
          value: filterType.EMPTY,
        },
      ],
    },
  ];

  const metadataFilterOptions = generateMetadataFilterOptions(filters.metadata);
  if (_isNotEmpty(metadataFilterOptions)) {
    result.push(...metadataFilterOptions);
  }

  if (_isNotEmpty(filters.fields)) {
    const filteredFields = addToStudy ? filters.fields.filter((field) => field.name !== 'Study') : filters.fields;

    filteredFields.forEach((field) => {
      const { name, value, options } = field;
      const fetchFromApi = filterOptionsFromApi(name);
      if (fetchFromApi) {
        result.push({
          value,
          name,
          operations: [
            {
              name: 'is equal to',
              value: filterType.eq,
              options: [],
            },
            {
              name: 'is not equal to',
              value: filterType.ne,
              options: [],
            },
          ],
          fetchFromApi: {
            placeholder: 'Search studies',
            url: 'studies.base',
          },
        });
      } else {
        result.push({
          value,
          name,
          operations: [
            {
              name: 'is equal to',
              value: filterType.eq,
              options: options,
            },
            {
              name: 'is not equal to',
              value: filterType.ne,
              options: options,
            },
          ],
        });
      }
    });
  }

  return result;
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ACTIONS':
      return {
        ...state,
        actions: action.data,
      };
    case 'SET_UPDATING':
      return {
        ...state,
        updating: action.data,
      };
    case 'SET_ERROR':
      return {
        ...state,
        apiErrors: action.data,
      };
    case 'SET_DATA':
      return {
        ...state,
        data: action.data.data,
        total_pages: action.data.pagination?.total_pages,
      };
    case 'SET_SEARCH_QUERY':
      return {
        ...state,
        searchQuery: action.data,
        current_page: 1,
      };
    case 'SET_SELECTED':
      return {
        ...state,
        selectedRows: action.data,
      };
    case 'SET_PAGING':
      return {
        ...state,
        current_page: state.per_page === action.data.per_page ? action.data.current_page : 1,
        per_page: action.data.per_page,
      };
    case 'SET_FILTERS':
      return {
        ...state,
        filters: action.data.filters,
        filterMatch: action.data.filterMatch,
        current_page: 1,
      };
    case 'SET_SORT':
      return {
        ...state,
        sort: action.data['sort'],
        order: action.data.order,
      };
    case 'SET_FILTER_OPTIONS':
      return {
        ...state,
        filterOptions: action.data,
      };
    case 'SET_COLUMNS': {
      return {
        ...state,
        columns: action.data,
      };
    }
    case 'SET_CAGE_ID_TO_PRINT':
      return {
        ...state,
        cageIdToPrint: action.data,
      };
    default: {
      return { ...state };
    }
  }
};
