import DatePickerNative from '@/components/UI/DatePickerNative/DatePickerNative';
import Switch from '@/components/UI/FormElements/Switch';
import { altIds } from '@/constants/utils';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import FormField from './FormField';

const startTabOptions = [
  { value: 'measurements', label: 'Measurements' },
  { value: 'samples', label: 'Samples' },
  { value: 'observations', label: 'Observations' },
  { value: 'dosing', label: 'Dosing' },
];

const General = () => {
  const { control } = useFormContext();

  return (
    <div>
      <FormField label="Show treatment groups" enableBorder={false}>
        <Controller
          defaultValue={false}
          name="workflowSetup.showGroups"
          control={control}
          render={({ value, onChange }: ControllerRenderProps) => (
            <Switch value={value} onChange={onChange} testId="show-treatment-groups-switch" />
          )}
        />
      </FormField>

      <FormField label="Set the date you would like to record">
        <Controller
          name="workflowSetup.measured_at"
          control={control}
          render={({ value, onChange }: ControllerRenderProps) => {
            return <DatePickerNative value={value} onChange={onChange} ddPrivacy="allow" />;
          }}
        />
      </FormField>
      <FormField label="ID method to search by">
        <Controller
          defaultValue="name"
          name="workflowSetup.idToSearch"
          control={control}
          render={({ value, onChange }: ControllerRenderProps) => (
            <select
              className="w4 dib mb0"
              onChange={(e) => onChange(e.target.value)}
              data-testid="id-to-search-dropdown"
            >
              {altIds.map((id) => (
                <option
                  value={id.key}
                  key={id.key}
                  data-testid={`id-to-search-option-${id.key}`}
                  selected={(value === 'name' ? value : `alt_ids.${value}`) === id.key}
                >
                  {id.title}
                </option>
              ))}
            </select>
          )}
        />
      </FormField>
      <FormField label="Display identifier">
        <Controller
          defaultValue="name"
          name="workflowSetup.idToDisplay"
          control={control}
          render={({ value, onChange }: ControllerRenderProps) => (
            <select className="w4 dib mb0" onChange={(e) => onChange(e.target.value)}>
              {altIds.map((id) => (
                <option
                  value={id.key}
                  key={id.key}
                  data-testid={`id-to-display-option-${id.key}`}
                  selected={(value === 'name' ? value : `alt_ids.${value}`) === id.key}
                >
                  {id.title}
                </option>
              ))}
            </select>
          )}
        />
      </FormField>
      <FormField label="Start on">
        <Controller
          name="workflowSetup.startTab"
          control={control}
          render={({ value, onChange }: ControllerRenderProps) => (
            <select
              className="dib mb0"
              style={{ width: '9rem' }}
              value={value}
              onChange={(changeEvent) => onChange(changeEvent.target.value)}
            >
              {startTabOptions.map(({ value, label }) => (
                <option key={value} value={value} data-testid={`start-on-option-${value}`}>
                  {label}
                </option>
              ))}
            </select>
          )}
        />
      </FormField>
    </div>
  );
};

export default General;
