import NoData from '@/components/NoData';
import {
  initialState,
  ScheduleContextProvider,
  scheduleContextReducer,
} from '@/components/Studies/Tasks/Schedule.context.ts';
import { ScheduleHeader } from '@/components/Studies/Tasks/ScheduleHeader.tsx';
import { Task } from '@/components/Studies/Tasks/Task';
import { TaskTypeData } from '@/components/Studies/Tasks/TaskTypeDetail.tsx';
import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import Spinner from '@/components/UI/Spinner/Spinner';
import { _isEmpty, _notNil } from '@/littledash';
import { ISODateTime } from '@/model/Common.model.ts';
import { Observation } from '@/model/Observation.model.ts';
import { SampleType } from '@/model/Sample.model.ts';
import type { Study } from '@/model/Study.model';
import { TaskApiId, TaskV1 } from '@/model/Task.model';
import { StudyTreatment } from '@/model/Treatment.model.ts';
import * as Auth from '@/support/auth';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { useApiPagination } from '@/support/Hooks/api/useApiPagination';
import { useLegacyApiHook } from '@/support/Hooks/api/useLegacyApiHook.ts';
import { RouteService } from '@/support/RouteService.ts';
import { DateRenderFormat, DateUtils } from '@/utils/Date.utils';
import { useModalAction } from '@/utils/modal.tsx';
import { useVirtualizer } from '@tanstack/react-virtual';
import { isToday } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import _groupBy from 'lodash/groupBy';
import { FC, memo, UIEvent, useMemo, useReducer, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

interface ScheduleProps {
  study: Study;
}

interface DateRow {
  type: 'date';
  value: string;
}

interface TaskRow {
  type: 'task';
  value: TaskV1;
}

const NextEntries = memo(() => (
  <div className="flex items-center justify-center pa3">
    <Spinner size="small" color="" /> <h3 className="f6 lh-title ml2">Loading...</h3>
  </div>
));

const DateItem = memo(({ value }: { value: string }) => (
  <div className="near-black f4 pv4">
    {isToday(toZonedTime(value, DateUtils.timezone())) && <span className="dib lh-title pr1 fw6">Today</span>}
    <DateTimeRenderer value={value} format={DateRenderFormat.Date} />
  </div>
));

export const generateVirtualRows = (tasks: Record<string, TaskV1[]>): Array<DateRow | TaskRow> => {
  return Object.keys(tasks).reduce((acc: Array<DateRow | TaskRow>, k: string) => {
    acc.push({ type: 'date', value: k } as DateRow);
    if (_notNil(tasks[k])) {
      tasks[k].forEach((task) => {
        acc.push({ type: 'task', value: task });
      });
    }
    return acc;
  }, []);
};

export const Schedule: FC<ScheduleProps> = ({ study }) => {
  // Stores any tasks updated to render instead of the API response
  const [updatedTasks, setUpdatedTasks] = useState<Record<TaskV1['id'], TaskV1>>({});
  const isWriteUser = Auth.isWriteUserForStudy(study);
  const [state, dispatch] = useReducer(scheduleContextReducer, initialState());
  const history = useHistory();
  const { openModal, closeModal } = useModalAction();

  const scrollElement = useRef<HTMLDivElement>(null);
  const {
    response: tasksDataResponse,
    loading: tasksDataLoading,
    invoke: fetchTasks,
  } = useApiHook({
    endpoint: 'GET /api/v1/tasks',
    query: {
      study_api_id: [study.api_id],
      sort: 'start',
      order: 'asc',
    },
  });

  const { response: samplesGlossaryResponse } = useLegacyApiHook({
    method: 'GET',
    apiRoute: 'team-glossary.list',
    query: { group: 'samples' },
    options: { onError: { toast: false, slug: 'samples-glossary-load' } },
  });
  const { response: observationsGlossaryResponse } = useLegacyApiHook({
    method: 'GET',
    apiRoute: 'team-glossary.list',
    query: { group: 'observations' },
    options: { onError: { toast: false, slug: 'observations-glossary-load' } },
  });
  const { response: treatmentsResponse } = useLegacyApiHook({
    method: 'GET',
    apiRoute: 'studies.treatments',
    path: { studyId: study.id },
    options: { onError: { toast: false, slug: 'treatments-load' } },
  });

  const taskTypeData = useMemo<TaskTypeData>(() => {
    const measurements = study.settings.calculations.reduce(
      (acc, calculation) => ({ ...acc, [calculation.id]: calculation.name }),
      {}
    );
    const samples = ((samplesGlossaryResponse?.body as { data: Array<SampleType> })?.data ?? []).reduce(
      (acc, sample) => ({ ...acc, [sample.id]: sample.title }),
      {}
    );
    const observations = ((observationsGlossaryResponse?.body as { data: Array<Observation> })?.data ?? []).reduce(
      (acc, observation) => ({ ...acc, [observation.id]: observation.title }),
      {}
    );
    const treatments = ((treatmentsResponse?.body as { data: Array<StudyTreatment> })?.data ?? []).reduce(
      (acc, treatment) => ({ ...acc, [treatment.id]: treatment.display_name }),
      {}
    );
    return {
      treatments,
      measurements,
      samples,
      observations,
    };
  }, [study, observationsGlossaryResponse, samplesGlossaryResponse, treatmentsResponse]);

  const { pages, hasNextPage, nextPage, reset } = useApiPagination({ response: tasksDataResponse });
  const { tasks, taskMap } = useMemo(() => {
    const taskList = (pages as Array<Array<TaskV1>>)?.flat() ?? [];
    const tasks: Record<string, TaskV1[]> = _groupBy(taskList, (task) => task?.duration?.start);
    const taskMap = new Map(taskList.map((task) => [task.id, task]));
    return { tasks, taskMap };
  }, [pages]);

  const rows: Array<DateRow | TaskRow> = useMemo(() => generateVirtualRows(tasks), [tasks]);

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => scrollElement?.current,
    estimateSize: () => 100,
    paddingEnd: 30,
  });
  const handleSearchChange = (data: { text: string | undefined; start: ISODateTime | undefined }) => {
    if (state.search.text !== data.text || state.search.start !== data.start) {
      dispatch({ type: 'update-search', data: { ...data } });
      reset();
      fetchTasks({
        query: {
          study_api_id: [study.api_id],
          sort: 'start',
          order: 'asc',
          text: data.text,
          start: data.start,
        },
      });
    }
  };

  const handleScrollBottom = (e: UIEvent<HTMLDivElement>): void => {
    if (e.target) {
      const { target } = e;
      if (!(target instanceof HTMLDivElement)) {
        return;
      }
      const { scrollTop, clientHeight, scrollHeight } = target;
      const atBottom = scrollTop + clientHeight >= scrollHeight;
      if (atBottom && hasNextPage && !tasksDataLoading) {
        fetchTasks({
          query: {
            study_api_id: [study.api_id],
            sort: 'start',
            order: 'asc',
            text: state.search.text,
            start: state.search.start,
            page: nextPage,
          },
        });
      }
    }
  };

  const handleTaskUpdate = (task: TaskV1): void => {
    setUpdatedTasks({ ...updatedTasks, [task.id]: task });
  };
  const openWorkflow = (taskApiIds: Array<TaskApiId>) => {
    history.push(
      RouteService.web({
        route: 'studies.workflow',
        path: { id: study.id },
        query: { type: 'task-execution', taskApiId: taskApiIds },
      }).route
    );
  };
  const handleTaskExecution = () => {
    openModal('EXECUTE_TASKS', {
      tasks: [...state.selectedTasks].reduce<Array<TaskV1>>(
        (acc, taskApiId) => (taskMap.has(taskApiId) ? [...acc, taskMap.get(taskApiId) as TaskV1] : acc),
        []
      ),
      onExecute: (taskApiIds: Array<TaskApiId>) => {
        closeModal();
        openWorkflow(taskApiIds);
      },
      onCancel: closeModal,
    });
  };

  return (
    <ScheduleContextProvider value={{ state, dispatch }}>
      <ScheduleHeader
        studyApiId={study.api_id}
        executeTasks={handleTaskExecution}
        onSearchChange={handleSearchChange}
      />
      {_isEmpty(rows) && !tasksDataLoading ? (
        <NoData
          title="No tasks found"
          text="Tasks can be added in the Study settings section."
          link={`/studies/${study.id}/settings/`}
          btnTxt="Study settings"
          isFullScreen
        />
      ) : (
        <div
          className="ph4 mb4"
          data-test-component="Schedule"
          data-test-element="scroll-container"
          ref={scrollElement}
          style={{
            height: '100%',
            overflowY: 'auto',
            contain: 'strict',
          }}
          onScroll={handleScrollBottom}
        >
          <div
            className="w-100 relative"
            style={{
              height: `${virtualizer.getTotalSize()}px`,
            }}
          >
            <div
              style={{
                transform: `translateY(${virtualizer.getVirtualItems()?.[0]?.start ?? 0}px)`,
              }}
              className="w-100 absolute top-0 left-0"
            >
              {virtualizer.getVirtualItems().map((virtualRow) => {
                const isLoaderRow = virtualRow.index >= rows.length - 1 && hasNextPage;
                const item = rows?.[virtualRow.index] as DateRow | TaskRow;
                if (item) {
                  return (
                    <div key={virtualRow.key} data-index={virtualRow.index} ref={virtualizer.measureElement}>
                      {item.type === 'date' ? (
                        <DateItem value={item.value} />
                      ) : (
                        <div className="pb3">
                          <Task
                            task={updatedTasks?.[item.value.id] ?? item.value}
                            study={study}
                            typeData={taskTypeData}
                            onTaskUpdate={handleTaskUpdate}
                            isWriteUser={isWriteUser}
                          />
                        </div>
                      )}
                      {isLoaderRow && <NextEntries />}
                    </div>
                  );
                } else {
                  return <></>;
                }
              })}
            </div>
          </div>
        </div>
      )}
    </ScheduleContextProvider>
  );
};
