import Button from '@/components/UI/Button';
import DatePickerNative from '@/components/UI/DatePickerNative';
import Radio from '@/components/UI/FormElements/Radio';
import { DateUtils } from '@/utils/Date.utils';
import type { FC } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form@latest';
import { AddTaskScheduleData, StepFormPayloadProps, type StepRenderProps } from '../../AddTask.model';
import styles from '../../AddTask.module.scss';
import ConditionalFields from './AddTaskScheduleConditionalFields';
import RecurringFields from './AddTaskScheduleRecurringFields';

interface AddTaskScheduleFormProps extends Omit<StepRenderProps, 'defaultValues'> {
  defaultValues?: AddTaskScheduleData;
}

const AddTaskScheduleForm: FC<AddTaskScheduleFormProps> = ({
  onSubmit,
  onCancel,
  submitButtonText,
  cancelButtonText,
  disabledFields = [],
}) => {
  const { control, handleSubmit: useFormHandleSubmit } = useFormContext<StepFormPayloadProps>();
  const selectedType = useWatch({ control, name: 'schedule.type' });

  return (
    <form
      onSubmit={useFormHandleSubmit(onSubmit)}
      className={styles['step-content']}
      data-test-component="AddTaskScheduleForm"
      data-test-element="form"
    >
      <div>
        <div className="ph3 pb3" data-test-component="OneOffScheduleForm" data-test-element="container">
          <Controller
            name="schedule.type"
            control={control}
            defaultValue="one_off"
            render={({ field }) => (
              <Radio
                onChange={field.onChange}
                id="one_off"
                value="one_off"
                label="One off"
                checked={selectedType === 'one_off'}
                disabled={disabledFields.includes(`schedule.type`)}
              />
            )}
          />
          {selectedType === 'one_off' && (
            <div className="pt3 ml4">
              <Controller
                name="schedule.start"
                control={control}
                defaultValue={DateUtils.dateNow()}
                render={({ field }) => (
                  <DatePickerNative
                    value={field.value ?? ''}
                    onChange={field.onChange}
                    className={styles['add-task-input-field']}
                  />
                )}
              />
            </div>
          )}
        </div>

        <div className="ph3 pb3" data-test-component="RecurringScheduleForm" data-test-element="container">
          <Controller
            name="schedule.type"
            control={control}
            defaultValue="one_off"
            render={({ field }) => (
              <Radio
                onChange={field.onChange}
                id="recurring"
                value="recurring"
                label="Recurring"
                checked={selectedType === 'recurring'}
                sublabel="Performed in a recurring pattern (eg. Monday and Wednesday)."
                disabled={disabledFields.includes('schedule.type')}
              />
            )}
          />
          {selectedType === 'recurring' && <RecurringFields disabledFields={disabledFields} />}
        </div>
        <div className="ph3 pb3" data-test-component="ConditionalScheduleForm" data-test-element="container">
          <Controller
            name="schedule.type"
            control={control}
            defaultValue="one_off"
            render={({ field }) => (
              <Radio
                onChange={field.onChange}
                id="conditional"
                value="conditional"
                label="Conditional"
                checked={selectedType === 'conditional'}
                sublabel="Generated once a condition is met (eg. age is 35 days)."
                disabled={disabledFields.includes('schedule.type')}
              />
            )}
          />
          {selectedType === 'conditional' && <ConditionalFields disabledFields={disabledFields} />}
        </div>
      </div>
      <div className="pa3 bt b--moon-gray mt3">
        <Button submit testKey="taskSpecStepFormSubmit">
          {submitButtonText}
        </Button>
        <Button plain className="ml2" onClick={onCancel}>
          {cancelButtonText}
        </Button>
      </div>
    </form>
  );
};

export default AddTaskScheduleForm;
