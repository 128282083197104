import logo from '@/assets/images/logo.svg';
import Loading from '@/components/Loading/Loading.tsx';
import Button from '@/components/UI/Button';
import Link from '@/components/UI/Link';
import { _isEmpty, _isNil, _isNotEmpty } from '@/littledash.ts';
import { PlatformLoginRequest, TeamSummary } from '@/model/Auth.model.ts';
import { emailPattern, LoginSubmit, SsoConfig, useLoginApi } from '@/pages/login/Login.util.ts';
import { web as webRoute } from '@/support/route.ts';
import { DateTimeRenderFormat, DateUtils } from '@/utils/Date.utils.ts';
import { ErrorMessage } from '@hookform/error-message';
import cn from 'classnames';
import { add, Duration, formatDuration } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form@latest';
import style from './Login.module.scss';

export const Login: FC = () => {
  const { state, loadConfig, platformLogin, teamLogin, logout } = useLoginApi();

  useEffect(() => {
    loadConfig();
  }, []);

  return (
    <div className="flex flex-column justify-center">
      <div className="flex flex-row justify-center w-100 mt6">
        <img className="dib" src={logo} alt="Benchling In Vivo" style={{ width: 150, height: 60 }} />
      </div>
      <div className="flex flex-row justify-center w-100 mt4" data-test-component="Login" data-test-element="container">
        {state.redeem_loading ? (
          <Loading txt="Logging in" />
        ) : (
          <>
            {_isNil(state.platformToken) ? (
              <LoginForm sso={state.sso} onSubmit={platformLogin} />
            ) : (
              <TeamPicker
                teams={state.teams}
                tokenValidDuration={state.token_valid_duration}
                onTeamSelect={teamLogin}
                onLogout={logout}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

interface LoginFormProps {
  sso: SsoConfig;
  onSubmit: LoginSubmit;
}

const LoginForm: FC<LoginFormProps> = ({ sso, onSubmit }) => {
  const formMethods = useForm<PlatformLoginRequest>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  });
  const handleSubmit: SubmitHandler<PlatformLoginRequest> = async (loginForm) => {
    const loginResult = await onSubmit(loginForm);
    switch (loginResult.type) {
      case 'unknown-error':
        formMethods.setError('root', {
          type: 'server',
          message: 'Something went wrong. Please try again or contact support.',
        });
        break;
      case 'unauthorised':
        formMethods.setError('root', { type: 'server', message: 'These credentials do not match our records.' });
        break;
      case 'cooldown': {
        const coolDownFinished = add(new Date(), loginResult.cooldownDuration).toISOString();
        formMethods.setError('root', {
          type: 'server',
          message: `Too many failed login attempts. Retry in ${formatDuration(loginResult.cooldownDuration)} (${DateUtils.renderDateTime(coolDownFinished, { format: DateTimeRenderFormat.Time })})`,
        });
        break;
      }
    }
  };
  return (
    <div className={style.loginForm} data-test-component="LoginForm" data-test-element="container">
      <form className="ui-card pa3" onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <div className="flex flex-column">
          <Controller
            name="email"
            control={formMethods.control}
            rules={{
              required: 'Email cannot be blank',
              pattern: { value: emailPattern, message: 'Please enter a valid email' },
            }}
            render={({ field, fieldState, formState }) => (
              <div className={cn({ [style.error]: fieldState.invalid })} data-test-element="email-container">
                <input
                  {...field}
                  type="email"
                  className={cn({ ui__error: fieldState.invalid })}
                  disabled={formState.isSubmitting || formState.isSubmitSuccessful}
                  placeholder="Email"
                  autoComplete="email"
                  data-test-element="email-input"
                />
                <ErrorMessage
                  errors={formState.errors}
                  name="email"
                  render={({ message }) => (
                    <p className={cn('f6 db pt2', style.red)} data-test-element="email-error">
                      {message}
                    </p>
                  )}
                />
              </div>
            )}
          />
          <ErrorMessage
            errors={formMethods.formState.errors}
            name="root"
            render={({ message }) => (
              <p className="f6 db pt2 red" data-test-element="root-error">
                {message}
              </p>
            )}
          />
          <Controller
            name="password"
            control={formMethods.control}
            rules={{ required: 'Password cannot be blank' }}
            render={({ field, fieldState, formState }) => (
              <div className={cn({ [style.error]: fieldState.invalid })} data-test-element="password-container">
                <input
                  {...field}
                  type="password"
                  className={cn({ ui__error: fieldState.invalid })}
                  disabled={formState.isSubmitting || formState.isSubmitSuccessful}
                  placeholder="Password"
                  autoComplete="current-password"
                  data-test-element="password-input"
                />
                <ErrorMessage
                  errors={formState.errors}
                  name="password"
                  render={({ message }) => (
                    <p className={cn('f6 db pt2', style.red)} data-test-element="password-error">
                      {message}
                    </p>
                  )}
                />
              </div>
            )}
          />
        </div>
        <Button
          className="w-100"
          submit
          disabled={formMethods.formState.isSubmitting || formMethods.formState.isSubmitSuccessful}
          loading={formMethods.formState.isSubmitting}
          loadingText="Logging in"
          testId="login-button"
        >
          Log in
        </Button>
      </form>
      <div className="mt4 db tc">
        {sso.enabled ? (
          <a href={webRoute('login.sso', { type: sso.type })} className="link ph2" data-testid="login-sso-login">
            Log in with SSO
          </a>
        ) : (
          <Link to={webRoute('password.forget')} className="link ph2" data-testid="login-forgot-password">
            Forgot password?
          </Link>
        )}
      </div>
    </div>
  );
};

interface TeamPickerProps {
  teams: Array<TeamSummary>;
  onTeamSelect: (team: TeamSummary) => Promise<void>;
  onLogout: () => void;
  tokenValidDuration?: Duration;
}

const TeamPicker: FC<TeamPickerProps> = ({ teams, tokenValidDuration, onTeamSelect, onLogout }) => {
  const [loading, setLoading] = useState(() => teams.length === 1);
  const handleTeamSelect = async (team: TeamSummary) => {
    setLoading(true);
    await onTeamSelect(team).catch(() => setLoading(false));
  };

  useEffect(() => {
    if (teams.length === 1) {
      onTeamSelect(teams[0]);
    }
  }, [teams, onTeamSelect]);

  return (
    <div className={style.teamPicker} data-test-component="TeamPicker" data-test-element="container">
      <div className="flex flex-column ui-card pa4">
        <TimeRemaining duration={tokenValidDuration} />
        {_isNotEmpty(teams) && teams.length > 1 && (
          <div>
            <h3 className="normal f4 near-black lh-copy pb2" data-test-element="team-select">
              Select a tenant
            </h3>
          </div>
        )}
        {_isEmpty(teams) ? (
          <div data-test-element="no-teams-container">
            <h3 className="normal f4 near-black lh-copy">You do not have access to any tenants</h3>
            <p>
              If you are seeing this screen and should have access to a tenant, your administrator may have enforced
              SSO. Please login through your SSO provider.
            </p>
            <Button url="mailto:support@benchling.com">Contact support</Button>
          </div>
        ) : (
          <div className={style.teamList}>
            {loading ? (
              <Loading txt="Signing in" />
            ) : (
              <ul data-test-element="team-list-container">
                {teams.map((team) => (
                  <li key={team.api_id} className="overflow-y-auto">
                    <a
                      className="blue link lh-copy db pv3 bt b--moon-gray"
                      onClick={() => handleTeamSelect(team)}
                      data-test-element="team-link"
                      data-test-key={team.api_id}
                    >
                      {team.name}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
      <div className="flex flex-row justify-center pa4">
        <a className="link mr2" onClick={onLogout}>
          &larr; Log out
        </a>
      </div>
    </div>
  );
};

const TimeRemaining: FC<{ duration?: Duration }> = ({ duration }) => {
  if (_isNil(duration) || (duration.minutes ?? 0) >= 1) {
    return null;
  }
  const minutes = `${duration.minutes ?? 0}`.padStart(1, '0');
  const seconds = `${duration.seconds ?? 0}`.padStart(2, '0');
  return (
    <div className="ui-card bn mb3 pa2 bg-red" style={{ backgroundColor: '#f6f8fa' }}>
      <h3 className="f6 fw5 mb1 near-black">Logging out due to inactivity</h3>
      <p className="f6 near-black">{`Time remaining ${minutes}:${seconds}`}</p>
    </div>
  );
};
