import Loading from '@/components/Loading';
import Button from '@/components/UI/Button';
import Radio from '@/components/UI/FormElements/Radio';
import { SelectMenu } from '@/components/UI/SelectMenu';
import { trunc } from '@/helpers';
import { _isEmptyString, _isNotEmpty, _notNil } from '@/littledash';
import { useFetchCollection } from '@/support/Hooks/fetch';
import useSearch from '@/support/Hooks/useSearch';
import { ErrorMessage } from '@hookform/error-message';
import cn from 'classnames';
import type { TreatmentGroup } from 'model/TreatmentGroup.model';
import type { FC, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form@latest';
import { StepFormPayloadProps, type StepRenderProps } from '../AddTask.model';
import styles from '../AddTask.module.scss';

const AddTaskTargetForm: FC<StepRenderProps> = ({
  onSubmit,
  onCancel,
  submitButtonText,
  cancelButtonText,
  studyId,
  studyCreationGroups,
  disabledFields = [],
}) => {
  const { control, watch, handleSubmit: useFormHandleSubmit, formState } = useFormContext<StepFormPayloadProps>();
  const selectedType = watch('target.type');

  return (
    <form
      data-test-component="AddTaskTargetForm"
      data-test-element="form"
      className={styles['step-content']}
      onSubmit={useFormHandleSubmit(onSubmit)}
    >
      <div data-test-element="GroupTargetAnimals">
        <label className="mb2 ph3">This task applies to:</label>
        <div className="mb2 ph3">
          <Controller
            name="target.type"
            control={control}
            defaultValue="animal"
            render={({ field }) => (
              <Radio
                onChange={field.onChange}
                id="animal"
                value="animal"
                label="All animals"
                checked={selectedType === 'animal'}
                disabled={disabledFields.includes('target.type')}
              />
            )}
          />
        </div>
        <div data-test-element="GroupTargetGroups">
          <div className="mb2 ph3">
            <Controller
              name="target.type"
              control={control}
              defaultValue="animal"
              render={({ field }) => (
                <Radio
                  onChange={field.onChange}
                  id="group"
                  value="group"
                  label="Groups"
                  checked={selectedType === 'group'}
                  disabled={disabledFields.includes('target.type')}
                />
              )}
            />
          </div>
          {selectedType === 'group' && (
            <div className={cn({ ui__disabled: disabledFields.includes('target.groups') })}>
              {_notNil(studyId) ? (
                <StudyGroupsProvider studyId={studyId}>
                  {(groups: TreatmentGroup[]) => <GroupMenu groups={groups} />}
                </StudyGroupsProvider>
              ) : (
                <GroupMenu groups={studyCreationGroups ?? []} />
              )}
            </div>
          )}
        </div>
      </div>
      <div className="pa3 bt b--moon-gray">
        <Button submit testKey="taskSpecStepFormSubmit" disabled={!formState.isValid}>
          {submitButtonText}
        </Button>
        <Button plain className="ml2" onClick={onCancel}>
          {cancelButtonText}
        </Button>
      </div>
    </form>
  );
};

const StudyGroupsProvider: FC<{ studyId: number; children: (studyGroups: TreatmentGroup[]) => ReactNode }> = ({
  studyId,
  children,
}) => {
  const { collection: groups, collectionLoading: loading } = useFetchCollection({
    collectionType: 'studyGroups',
    params: { id: studyId },
    queryParams: { perPage: -1 },
  });

  if (loading) {
    return <Loading />;
  }

  return <>{children(groups as TreatmentGroup[])}</>;
};

const GroupMenu: FC<{ groups: TreatmentGroup[] }> = ({ groups }) => {
  const { control, formState } = useFormContext<StepFormPayloadProps>();
  const { search, searchResults, resetSearch } = useSearch({
    items: groups,
    accessors: ['name'],
  });

  const handleSearch = (query: string): void => {
    if (_isEmptyString(query)) {
      resetSearch();
    } else {
      search(query);
    }
  };

  const groupResults = searchResults.map((result) => ({
    name: trunc(result.name, 30),
    // Study creation forms create a `uuid` => `temp_id` as part of the payload
    value: result?.temp_id ?? result.api_id,
  }));

  return (
    <>
      <ErrorMessage
        errors={formState.errors}
        name="target.groups"
        render={({ message }) => <p className="f6 red db pv2 ph3">{message}</p>}
      />
      <Controller
        name="target.groups"
        defaultValue={[]}
        control={control}
        rules={{ validate: (value: any) => (_isNotEmpty(value) ? true : 'At least one group is required') }}
        render={({ field }) => (
          <SelectMenu
            preSelectedValues={field.value}
            onSearch={handleSearch}
            onSelect={field.onChange}
            items={groupResults}
            searchPlaceholderText="Search by name"
          />
        )}
      />
    </>
  );
};

export default AddTaskTargetForm;
