import { _isNil, _notNil } from '@/littledash';
import type { ISODate, ISODateTime, Nullable } from '@/model/Common.model';
import store from '@/store';
import { format, isValid } from 'date-fns';
import { formatInTimeZone, fromZonedTime, getTimezoneOffset, toDate, toZonedTime } from 'date-fns-tz';

export const enum DateRenderFormat {
  Date = 'd MMM yyyy',
  ISODate = 'yyyy-MM-dd',
  DateDefaultWithDay = 'eeee d MMMM yyyy',
  DateWithoutYear = 'd MMM',
}

export const enum DateTimeRenderFormat {
  DateTimeDefault = 'd MMM yyyy, HH:mm',
  TimeWithSeconds = 'HH:mm:ss',
  Time = 'HH:mm',
}

export interface RenderDateOptions {
  format?: DateRenderFormat;
  defaultResponse?: string;
}

export interface RenderDateTimeOptions {
  format?: DateTimeRenderFormat | DateRenderFormat;
  defaultResponse?: string;
}

export class DateUtils {
  private static InvalidDate = 'Invalid Date';
  static isoDatePattern = /^(?<year>\d{4})-(?<month>[0-1]\d)-(?<day>[0-3]\d)$/;
  static isoDateTimePattern =
    /^(?<year>\d{4})-(?<month>[0-1]\d)-(?<day>[0-3]\d)T(?<hour>[0-2]\d):(?<minute>[0-5]\d):(?<second>[0-5]\d)\.(?<millisecond>\d+)Z$/;

  static browserTimezone(defaultTimezone = 'UTC'): string {
    return Intl.DateTimeFormat()?.resolvedOptions()?.timeZone ?? defaultTimezone;
  }

  static timezone(): string {
    return store.getState()?.user?.currentUser?.timezone ?? DateUtils.browserTimezone();
  }

  static renderDate(isoDate: Nullable<ISODate>, renderDateOption: RenderDateOptions = {}): string {
    if (_notNil(isoDate) && DateUtils.isoDatePattern.test(isoDate)) {
      const timeZone = DateUtils.timezone();
      const date = toDate(isoDate, { timeZone });
      if (isValid(date)) {
        return formatInTimeZone(date, timeZone, renderDateOption?.format ?? DateRenderFormat.Date);
      }
    }
    return renderDateOption?.defaultResponse ?? DateUtils.InvalidDate;
  }

  static renderDateTime(isoDateTime: Nullable<ISODateTime>, renderDateTimeOptions: RenderDateTimeOptions = {}): string {
    if (_notNil(isoDateTime) && DateUtils.isoDateTimePattern.test(isoDateTime)) {
      const date = toZonedTime(isoDateTime, DateUtils.timezone());
      if (isValid(date)) {
        return format(date, renderDateTimeOptions?.format ?? DateTimeRenderFormat.DateTimeDefault);
      }
    }
    return renderDateTimeOptions?.defaultResponse ?? DateUtils.InvalidDate;
  }

  static dateTimeNow(): ISODateTime {
    return new Date().toISOString();
  }

  static dateNow(): ISODate {
    return DateUtils.formatDate(new Date());
  }

  static timezoneName(
    isoDateTime: Nullable<ISODateTime>,
    timeZone: string,
    type: 'short' | 'long' | 'shortOffset' | 'longOffset' | 'shortGeneric' | 'longGeneric' = 'long'
  ): string | undefined {
    return new Intl.DateTimeFormat(window.navigator.languages, { timeZone, timeZoneName: type })
      .formatToParts(_isNil(isoDateTime) ? new Date() : new Date(isoDateTime))
      .find((part) => part.type === 'timeZoneName')?.value;
  }
  static timezoneAbbreviation(isoDateTime: Nullable<ISODateTime>, timezone: string): string {
    return formatInTimeZone(_isNil(isoDateTime) ? new Date() : new Date(isoDateTime), timezone, 'zzz');
  }

  static timezoneOffsetMilliseconds(isoDateTime: Nullable<ISODateTime>, timeZone: string): number {
    return getTimezoneOffset(timeZone, _isNil(isoDateTime) ? new Date() : new Date(isoDateTime));
  }

  static formatDate(date: Date): ISODate {
    return format(date, 'yyyy-MM-dd');
  }

  static roundDate(date: Date, by: 'seconds' = 'seconds'): Date {
    switch (by) {
      case 'seconds':
        if (date.getMilliseconds() > 500) {
          date.setSeconds(date.getSeconds() + 1, 0);
        } else {
          date.setSeconds(date.getSeconds(), 0);
        }
        break;
    }
    return date;
  }

  static isValidDate(isoDate: Nullable<ISODate>): isoDate is string & boolean {
    return _notNil(isoDate) && DateUtils.isoDatePattern.test(isoDate) && isValid(toZonedTime(isoDate, 'UTC'));
  }

  static isValidDateTime(isoDateTime: Nullable<ISODateTime>): isoDateTime is string & boolean {
    return (
      _notNil(isoDateTime) &&
      DateUtils.isoDateTimePattern.test(isoDateTime) &&
      isValid(toZonedTime(isoDateTime, DateUtils.timezone()))
    );
  }

  static convertSecondsToUnit(seconds: number, unit: 'MINUTES' | 'HOURS' | 'DAYS'): number {
    switch (unit) {
      case 'MINUTES':
        return seconds / 60;
      case 'HOURS':
        return seconds / 3600;
      case 'DAYS':
        return seconds / 86400;
    }
    return seconds;
  }

  static convertUnitToSeconds(seconds: number, unit: 'MINUTES' | 'HOURS' | 'DAYS'): number {
    switch (unit) {
      case 'MINUTES':
        return seconds * 60;
      case 'HOURS':
        return seconds * 3600;
      case 'DAYS':
        return seconds * 86400;
    }
    return seconds;
  }

  static startOfDay(isoDate: ISODate): ISODateTime {
    if (DateUtils.isValidDate(isoDate)) {
      return toDate(`${isoDate}T00:00:00.000`, { timeZone: DateUtils.timezone() }).toISOString();
    }
    return 'Invalid date';
  }

  static endOfDay(isoDate: ISODate): ISODateTime {
    if (DateUtils.isValidDate(isoDate)) {
      return toDate(`${isoDate}T23:59:59.999`, { timeZone: DateUtils.timezone() }).toISOString();
    }
    return 'Invalid date';
  }
}

export class DateInputUtils {
  private static localDateTimePattern =
    /^(?<year>\d{4})-(?<month>[0-1]\d)-(?<day>[0-3]\d)T(?<hour>[0-2]\d):(?<minute>[0-5]\d)[0-9:.]{0,7}$/;
  static hotDatePattern = 'YYYY-MM-DD';

  static toLocalDateTime(isoDateTime: Nullable<ISODateTime>, options?: { includeSeconds?: boolean }): string | '' {
    if (_notNil(isoDateTime) && DateUtils.isoDateTimePattern.test(isoDateTime)) {
      const date = toZonedTime(isoDateTime, DateUtils.timezone());
      if (isValid(date)) {
        return format(date, `yyyy-MM-dd'T'HH:mm${(options?.includeSeconds ?? true) ? ':ss' : ''}`);
      }
    }
    return '';
  }

  static localDateTimeToISODateTime(localDateTime: string): ISODateTime | '' {
    if (DateInputUtils.localDateTimePattern.test(localDateTime)) {
      const date = fromZonedTime(localDateTime, DateUtils.timezone());
      if (isValid(date)) {
        return date.toISOString();
      }
    }
    return '';
  }
}

export class DateTestUtils {
  static generateRandomDate(
    start: Readonly<Date> = new Date(-8640000000000000),
    end: Readonly<Date> = new Date(8640000000000000)
  ): Date {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
  }

  static randomDate(
    start: Readonly<Date> = new Date(-8640000000000000),
    end: Readonly<Date> = new Date(8640000000000000)
  ): ISODate {
    return format(DateTestUtils.generateRandomDate(start, end), DateRenderFormat.ISODate);
  }

  static randomDateTime(
    start: Readonly<Date> = new Date(-8640000000000000),
    end: Readonly<Date> = new Date(8640000000000000)
  ): ISODateTime {
    return DateTestUtils.generateRandomDate(start, end).toISOString();
  }
}
