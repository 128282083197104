import { TaskDetailsTabType } from '@/components/Modals/TaskDetails/TaskDetails.tsx';
import { TypedTaskData } from '@/components/Modals/TaskDetails/TaskDetailsTabs/ExecutionPanel.tsx';
import { TaskTime } from '@/components/Modals/TaskDetails/TaskDetailsTabs/TaskTime.tsx';
import Link from '@/components/UI/Link';
import UserAvatar from '@/components/UI/UserAvatar';
import { _isEmptyString, _isNotEmpty, _notNil } from '@/littledash';
import type { PickRequired } from '@/model/Common.model';
import { GlossaryItem } from '@/model/Glosary.model.ts';
import { PresetCalculation } from '@/model/PresetCalculation.model.ts';
import type { TaskType, TaskV1 } from '@/model/Task.model';
import { Treatment } from '@/model/Treatment.model.ts';
import { web } from '@/support/route';
import { useModalAction } from '@/utils/modal';
import { type FC, type PropsWithChildren, useMemo } from 'react';
import { FiUserPlus } from 'react-icons/fi';
import { RiCalendarLine, RiCheckboxMultipleLine } from 'react-icons/ri';

interface TaskInfoRowProps {
  label: string;
}

const taskTypeLabels: Record<TaskType, string> = {
  dosing: 'Dosing',
  measurement: 'Measurement',
  observation: 'Observation',
  sample: 'Sample',
  other: 'Other',
};

const TypedTaskInfoRow: FC<{ task: TaskV1; typedTaskData?: TypedTaskData; onClick: () => void }> = ({
  task,
  typedTaskData,
  onClick,
}) => {
  const { title, items } = useMemo(() => {
    switch (task?.type) {
      case 'measurement': {
        const data: Map<PresetCalculation['id'], PresetCalculation> =
          typedTaskData?.type === 'measurement' ? typedTaskData.data : new Map();
        const items = (task?.execution?.measurement?.measurements ?? []).reduce<Array<string>>((acc, id) => {
          const calculation = data.get(id);
          if (_notNil(calculation)) {
            const unit = _isEmptyString(calculation.unit ?? '') ? '' : ` ( ${calculation.unit} )`;
            return [...acc, `${calculation.name}${unit}`];
          }
          return acc;
        }, []);
        return { title: 'Measurements', items };
      }
      case 'sample': {
        const data: Map<GlossaryItem['id'], GlossaryItem> =
          typedTaskData?.type === 'sample' ? typedTaskData.data : new Map();
        const items = (task?.execution?.sample?.samples ?? []).reduce<Array<string>>((acc, id) => {
          const glossaryItem = data.get(id);
          if (_notNil(glossaryItem)) {
            return [...acc, glossaryItem.title];
          }
          return acc;
        }, []);
        return { title: 'Samples', items };
      }
      case 'observation': {
        const data: Map<GlossaryItem['id'], GlossaryItem> =
          typedTaskData?.type === 'observation' ? typedTaskData.data : new Map();
        const items = (task?.execution?.observation?.observations ?? []).reduce<Array<string>>((acc, id) => {
          const glossaryItem = data.get(id);
          if (_notNil(glossaryItem)) {
            return [...acc, glossaryItem.title];
          }
          return acc;
        }, []);
        return { title: 'Observations', items };
      }
      case 'dosing': {
        const data: Map<Treatment['id'], Treatment> = typedTaskData?.type === 'dosing' ? typedTaskData.data : new Map();
        const items = (task?.execution?.dosing?.treatments ?? []).reduce<Array<string>>((acc, id) => {
          const treatment = data.get(id);
          if (_notNil(treatment)) {
            return [...acc, treatment.display_name];
          }
          return acc;
        }, []);
        return { title: 'Treatments', items };
      }
      default:
        return { title: '', items: [] };
    }
  }, [task, typedTaskData]);
  if (items.length <= 0) {
    return null;
  }
  return (
    <TaskInfoRow label={title}>
      <div className="flex flex-column link" onClick={onClick}>
        {items.slice(0, 3).map((item, idx) => (
          <span key={idx}>{item}</span>
        ))}
        {items.length > 3 ? <span>+{items.length - 3} More</span> : null}
      </div>
    </TaskInfoRow>
  );
};

const TaskInfoRow: FC<PropsWithChildren<TaskInfoRowProps>> = ({ label, children }) => (
  <div className="flex items-center">
    <div className="flex flex-column">
      <p className="ml3" style={{ width: '90px' }}>
        {label}
      </p>
    </div>
    <div className="flex flex-column">
      <p className="black pl3 ui__overflow__scroll_y" style={{ maxWidth: '340px' }}>
        {children}
      </p>
    </div>
  </div>
);

export const OverviewPanel: FC<{
  task: PickRequired<TaskV1, 'animals'>;
  typedTaskData?: TypedTaskData;
  setActiveTab: (tab: TaskDetailsTabType) => void;
}> = ({ task, typedTaskData, setActiveTab }) => {
  const { closeModal } = useModalAction();
  const groupCount = useMemo(
    () =>
      task.animals.reduce((acc, { group }) => {
        if (_notNil(group)) {
          acc.add(group.api_id);
        }
        return acc;
      }, new Set()).size,
    [task]
  );

  return (
    <div className="pt3 pr4">
      <div className="pl4">
        <div className="pa3 mt2 w-100 bg-light-gray" style={{ height: '100px' }}>
          <div className="flex mt1">
            <span className="ml3 mr4 black">
              <RiCalendarLine size={20} />
            </span>
            <p className="black fw5 f6">
              <TaskTime timezone={task.timezone} taskDuration={task.duration} />
            </p>
          </div>
          <div className="flex mt2">
            <span className="ml3 mr4 black">
              <RiCheckboxMultipleLine size={20} />
            </span>
            <p className="black fw4 f6 link" onClick={() => setActiveTab('target')}>
              Affects {task.animals.length} animals{' '}
              {task.target.type === 'group' && (
                <>
                  in {groupCount} / {task.target.groups.length} groups
                </>
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="mv4 pl3 ml1 f6 min-h4 w-100">
        <div className="mt3">
          <TaskInfoRow label="Type">{taskTypeLabels[task.type]}</TaskInfoRow>
        </div>
        <TypedTaskInfoRow task={task} typedTaskData={typedTaskData} onClick={() => setActiveTab('execution')} />
        <div className="mt3">
          <TaskInfoRow label="Study">
            <Link className="link" to={web('studies.show', { id: task.study.id })} onClick={() => closeModal()}>
              {task.study.name}
            </Link>
          </TaskInfoRow>
        </div>

        {_isNotEmpty(task.assignees) && (
          <div className="mt2">
            <TaskInfoRow label="Assignees">
              <div className="flex items-center">
                {task.assignees.map(({ id, name }) => (
                  <span className="mr1" key={id}>
                    <UserAvatar user={{ name }} />
                  </span>
                ))}
                {task.study.active && (
                  <div
                    className="flex pa2 link"
                    onClick={() => setActiveTab('assignees')}
                    style={{ border: '1px dashed lightgrey', borderRadius: '23px' }}
                  >
                    <FiUserPlus size={20} />
                  </div>
                )}
              </div>
            </TaskInfoRow>
          </div>
        )}
        {_isNotEmpty(task.description) && (
          <div className="mt2">
            <TaskInfoRow label="Description">{task.description}</TaskInfoRow>
          </div>
        )}
      </div>
    </div>
  );
};
