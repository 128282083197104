import { taskTypes } from '@/components/Studies/Tasks/Task.tsx';
import { TaskApiId, TaskV1 } from '@/model/Task.model.ts';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal.tsx';
import cn from 'classnames';
import { FC, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { RiDraggable } from 'react-icons/ri';

type ExecuteTasksModalProps = {
  tasks: Array<TaskV1>;
  onExecute: (taskApiIds: Array<TaskApiId>) => void;
  onCancel: () => void;
};

const TaskCard: FC<{ task: TaskV1; index: number; onDrop: (source: number, destination: number) => void }> = ({
  task,
  index,
  onDrop,
}) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const [{ opacity }, dragRef] = useDrag({
    item: {
      type: 'TaskCard',
      taskApiId: task.id,
      sourceIndex: index,
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
      isDragging: monitor.isDragging(),
    }),
  });
  const [{ isOver }, dropRef] = useDrop({
    accept: 'TaskCard',

    // @ts-expect-error: untyped
    canDrop: (item) => item.sourceIndex !== index,
    drop: (item) => {
      // @ts-expect-error: untyped
      if (Number.isFinite(item.sourceIndex) && item.sourceIndex !== index) {
        // @ts-expect-error: untyped
        onDrop(item.sourceIndex, index);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.canDrop() && monitor.isOver(),
    }),
  });
  dragRef(dropRef(cardRef));
  return (
    <div
      data-test-component="TaskCard"
      data-test-element="container"
      data-test-key={task.id}
      ref={cardRef}
      className={cn('flex flex-row justify-between items-center bg-near-white mh4 pa3 mt2 ba', {
        'b--blue': isOver,
      })}
      style={{ opacity }}
    >
      <div className="flex flex-column f5 near-black">
        <div className="fw5">{task.title}</div>
        <div className="f6 fw1">{taskTypes[task.type]}</div>
      </div>
      <div>
        <RiDraggable size={25} className="dark-gray" />
      </div>
    </div>
  );
};

export const ExecuteTasksModal: FC<ExecuteTasksModalProps> = ({ tasks, onExecute, onCancel }) => {
  const [taskList, updateTaskList] = useState(() => tasks);

  const handleDrop = (source: number, destination: number) => {
    updateTaskList((previous) => {
      const update = [...previous];
      const [movedTask] = update.splice(source, 1);
      update.splice(destination, 0, movedTask);
      return update;
    });
  };
  return (
    <ModalContainer size="small" testKey="execute-tasks-modal">
      <ModalHeader
        title="Execute tasks"
        subText={
          <span>
            Click and drag the <RiDraggable className="dark-gray" /> icon to specify the order tasks will be completed
          </span>
        }
        subTextClasses="f6 lh-copy mt1 measure-wide"
        closeModal={onCancel}
      />
      <div className="flex flex-column pv2 overflow-x-auto overflow-y-hidden">
        {taskList.map((task, index) => (
          <TaskCard key={`${task.id}___${index}`} task={task} index={index} onDrop={handleDrop} />
        ))}
      </div>
      <ModalActions
        className="pa3 mt3"
        submitBtnText="Continue"
        cancelBtnText="Close"
        onSubmit={() => onExecute(taskList.map((t) => t.id))}
        onCancel={() => onCancel()}
      />
    </ModalContainer>
  );
};
