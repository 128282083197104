// @ts-nocheck: converted from JS

import { forwardRef, useEffect, useRef } from 'react';

const IndeterminateCheckbox = forwardRef(({ indeterminate, disableSelectAll, style, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  if (disableSelectAll) {
    style.display = 'none';
  }

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <input type="checkbox" className="ui-table-selector percentCenter" ref={resolvedRef} style={style} {...rest} />
  );
});

const Selector = (disableSelectAll, onSelectRow) => ({
  id: 'selection',
  width: 44,
  minWidth: 44,
  canExpand: false,
  sortDisabled: true,
  isVisible: true,
  Header: ({ getToggleAllRowsSelectedProps, toggleAllRowsSelected, isAllRowsSelected, rows, state }) => {
    let props = {};
    if (onSelectRow) {
      props = {
        ...props,
        onClick: () => {
          toggleAllRowsSelected();
          onSelectRow(isAllRowsSelected ? [] : rows, state);
        },
      };
    }
    return <IndeterminateCheckbox {...getToggleAllRowsSelectedProps(props)} disableSelectAll={disableSelectAll} />;
  },
  Cell: ({ rows, row, toggleRowSelected, selectedFlatRows, state }) => {
    const handleSelect = (e: Event) => {
      let rowsSelected;
      if (e.shiftKey) {
        // Shift select
        if (!row.isSelected) {
          const originRow = selectedFlatRows[0];
          const indexRowRange = [originRow?.index ?? 0, row?.index ?? 0].sort((a, b) => a - b);
          const rowSlice = (rows ?? []).slice(indexRowRange[0], indexRowRange[1]);
          rowsSelected = [...rowSlice, row];
          (rowSlice ?? []).forEach((row) => {
            if (!row.isSelected) {
              toggleRowSelected(row.index);
            }
          });
        }
      } else {
        // Single select
        if (row?.isSelected) {
          // the row is now un-selected. Remove it from `rowsSelected`
          rowsSelected = (selectedFlatRows ?? []).filter((selectedRow) => selectedRow.id !== row.id);
        } else {
          // the row is now selected. Add it to `rowsSelected`
          rowsSelected = [...selectedFlatRows, row];
        }
        toggleRowSelected(row.index);
      }
      if (onSelectRow) {
        onSelectRow(rowsSelected, state);
      }
    };
    const props = {
      onClick: handleSelect,
    };

    return <IndeterminateCheckbox {...row.getToggleRowSelectedProps(props)} />;
  },
});

export default Selector;
