import { useScheduleContext } from '@/components/Studies/Tasks/Schedule.context.ts';
import { ScheduleSearchBar } from '@/components/Studies/Tasks/ScheduleSearchBar.tsx';
import Button from '@/components/UI/Button';
import { ISODateTime } from '@/model/Common.model.ts';
import { StudyApiId } from '@/model/Study.model.ts';
import { useApiHook } from '@/support/Hooks/api/useApiHook.ts';
import { featuresSelector } from '@/support/Selectors.tsx';
import { createSelector } from '@reduxjs/toolkit';
import { FC } from 'react';
import { useSelector } from 'react-redux';

const typedTaskFeatureSelector = createSelector([featuresSelector], (features) => features?.typed_tasks ?? false);

export const ScheduleHeader: FC<{
  studyApiId: StudyApiId;
  executeTasks: () => void;
  onSearchChange: (data: { text: string | undefined; start: ISODateTime | undefined }) => void;
}> = ({ studyApiId, executeTasks, onSearchChange }) => {
  const { state } = useScheduleContext();
  const typedTasksEnabled = useSelector(typedTaskFeatureSelector);
  const { response: scheduleDataResponse } = useApiHook({
    endpoint: 'GET /api/v1/studies/{studyId}/task-schedule-data',
    path: { studyId: studyApiId },
  });
  return (
    <div className="ph4 pv2 bb b-moon-gray" data-test-component="ScheduleHeader" data-test-element="container">
      <div className="flex flex-row justify-between items-center">
        <h1 className="f3 normal">Schedule</h1>
        {typedTasksEnabled ? (
          <>
            <span style={{ width: '50vw' }}>
              <ScheduleSearchBar onChange={onSearchChange} scheduleData={scheduleDataResponse?.body} />
            </span>
            <div>
              <Button
                disabled={state.selectedTasks.size <= 0}
                testId="execute-task-button"
                onClick={() => executeTasks()}
              >
                {state.selectedTasks.size > 1 ? <>Execute tasks</> : <>Execute task</>}
              </Button>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};
