import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers.tsx';
import { _isEmptyString } from '@/littledash.ts';
import { State } from '@/model/State.model.ts';
import { TaskDuration } from '@/model/Task.model.ts';
import { DateRenderFormat, DateTimeRenderFormat, DateUtils } from '@/utils/Date.utils.ts';
import { formatDuration, intervalToDuration } from 'date-fns';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

export const TaskTime: FC<{ taskDuration: TaskDuration; timezone: string }> = ({ taskDuration, timezone }) => {
  const userTimezone = useSelector((state: State) => state.user.currentUser.timezone);
  const { isAllDayTask, textDuration, dateRenderFormat, displayTimezone, timezoneData } = useMemo(() => {
    const displayTimezone = timezone !== userTimezone;
    const startDate = new Date(taskDuration.start);
    const endDate = DateUtils.roundDate(new Date(taskDuration.end));
    const timezoneData = {
      id: timezone,
      name: DateUtils.timezoneName(taskDuration.start, timezone, 'long') ?? '',
      abbreviation: DateUtils.timezoneAbbreviation(taskDuration.start, timezone),
    };
    const duration = intervalToDuration({ start: startDate, end: endDate });
    const isAllDayTask = Object.keys(duration).length === 1 && duration.days === 1;
    const textDuration = formatDuration(duration, { zero: false });
    const dateRenderFormat: DateRenderFormat | DateTimeRenderFormat =
      isAllDayTask && !displayTimezone ? DateRenderFormat.Date : DateTimeRenderFormat.DateTimeDefault;

    return {
      displayTimezone,
      dateRenderFormat,
      isAllDayTask,
      textDuration,
      timezoneData,
    };
  }, [userTimezone, taskDuration, timezone]);

  return (
    <span
      data-tooltip-id="global-tooltip-id"
      data-tooltip-content={
        displayTimezone
          ? `${_isEmptyString(timezoneData.name) ? timezoneData.id : timezoneData.name} • ${timezoneData.abbreviation}`
          : undefined
      }
    >
      <span>
        <DateTimeRenderer value={taskDuration.start} format={dateRenderFormat} />
      </span>
      {isAllDayTask && !displayTimezone ? <></> : <span className="ml1">for {textDuration}</span>}
    </span>
  );
};
