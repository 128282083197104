// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import Filters from '@/components/UI/Filters';
import Selector from '@/components/UI/Table/Columns/Selector';
import { TableProvider } from '@/components/UI/Table/TableContext';
import TableWithContext from '@/components/UI/Table/TableWithContext';
import { successToast } from '@/helpers';
import { _isEmpty, _isNil, _isNotEmpty } from '@/littledash';
import { notAborted, useAbortController } from '@/support/Hooks/fetch/useAbortController';
import Http from '@/support/http';
import { structuredCloneUtil } from '@/utils/browser.utils.ts';
import type { AxiosResponse } from 'axios';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFlexLayout, usePagination, useResizeColumns, useRowSelect, useSortBy, useTable } from 'react-table7';
import ActionBar from './ActionBar';
import { ApiInfo, APITableProps } from './APITable.model';
import { downloadFile, getExportUrl, getParams, saveTableSettings } from './APITable.utils';
import ExportButton from './ExportButton';

const invokeFetchTableData = async (
  { type, route }: ApiInfo,
  queryParams: string,
  signal: AbortSignal
): Promise<AxiosResponse<{ data: any; meta: any }>> => {
  switch (type) {
    case 'internalApi':
      return Http.request({ method: 'GET', baseURL: `${route.origin}${route.pathname}${queryParams}`, signal });
    case 'legacyInternalApi':
      return Http.get(`${route}${queryParams}`, { signal });
    default:
      return Promise.reject(new Error(`Unknown API type [${type}]`));
  }
};

const APITable = <Resp extends object>({
  columns = [],
  filterOptions,
  apiInfo,
  queryParams,
  onFetchTableData = () => {},
  includeParam,
  excludeParam,
  customFilter,
  AsideComponent: ExternalAsideComponent,
  NoDataComponent,
  bulkActions,
  exportRoutes,
  searchPlaceholderText = '',
  loadingText,
  defaultSortBy = { id: 'created_at', desc: true },
  pageSizes = [10, 25, 50, 1000],
  searchQueryByOptions = [],
  settings,
  showMetricChangeFrom = false,
  renderCustomActions,
  reduxTableName,
  hideSearch,
  hideFilter,
  onSelectRow,
  refetch,
  noDataMessage,
  testIdPrefix = '',
  loadingClassName = 'pv7',
  maxHeight,
}: APITableProps<Resp>): React.ReactElement => {
  const tableSettings = useMemo(() => {
    const tableSettings = settings?.tables?.[reduxTableName];
    return _isNil(tableSettings) ? null : structuredCloneUtil(tableSettings);
  }, [reduxTableName, settings]);

  const initialTableState = {
    tableData: [],
    searchQuery: '',
    queryBy: tableSettings?.queryBy ?? searchQueryByOptions?.[0]?.value,
    filters: [],
    filterMatch: true,
    pageCount: 0,
    pageSize: reduxTableName === 'animals' ? 1000 : (tableSettings?.pageSize ?? pageSizes[0]),
  };
  const [tableData, setTableData] = useState(initialTableState.tableData);
  const [searchQuery, setSearchQuery] = useState(initialTableState.searchQuery);
  const [queryBy, setQueryBy] = useState(initialTableState.queryBy);
  const [filters, setFilters] = useState(initialTableState.filters);
  const [filterMatch, setFilterMatch] = useState(initialTableState.filterMatch);
  const [pageCount, setPageCount] = useState(initialTableState.pageCount);
  const [totalItemCount, setTotalItemCount] = useState<number | null>(null);
  const [selectAllActive, setSelectAllActive] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [apiError, setApiError] = useState(false);
  const { newAbortController } = useAbortController();
  const tableRef = useRef();
  const hasSelectorColumn = bulkActions || renderCustomActions || onSelectRow;
  const memoColumns = useMemo(() => {
    if (hasSelectorColumn) {
      return [Selector(false, onSelectRow), ...columns];
    }
    return columns;
  }, [columns, settings?.changePercentage, settings?.tables]);

  const memoSortBy = useMemo(() => [defaultSortBy], [defaultSortBy]);

  const fetchTableData = async (resetPage) => {
    const params = getParams({
      searchQuery,
      searchQueryBy: queryBy ?? initialTableState.queryBy ?? 'name',
      filters,
      filterMatch,
      sortBy,
      pageSize,
      pageIndex,
      includeParam,
      excludeParam,
      resetPage,
      customFilter,
      queryParams,
      urlSearchParams: apiInfo.type === 'internalApi' ? (apiInfo.route as URL).searchParams : undefined,
    });
    setUpdating(true);
    try {
      const {
        data: { data, meta },
      } = await invokeFetchTableData(apiInfo, params, newAbortController().signal);
      setTableData(data ?? []);
      if (onFetchTableData) {
        onFetchTableData(data);
      }
      setPageCount(meta?.pagination?.total_pages ?? meta?.last_page);
      setTotalItemCount(meta?.total ?? null);
      setApiError(false);
      setLoading(false);
      setUpdating(false);
      return data;
    } catch (error) {
      if (notAborted(error)) {
        setApiError(error);
        setLoading(false);
        setUpdating(false);
      }
    }
  };
  const resetTableState = () => {
    setPageSize(initialTableState.pageSize);
    setSelectAllActive(false);
    toggleAllRowsSelected(false);
    setSearchQuery(initialTableState.searchQuery);
    setQueryBy(initialTableState.searchQueryBy);
    setFilters(initialTableState.filters);
    setFilterMatch(initialTableState.filterMatch);
    setPageCount(initialTableState.pageCount);
  };

  const useTableProps = useTable(
    {
      columns: memoColumns,
      data: tableData,
      manualSortBy: true,
      manualPagination: true,
      initialState: {
        pageIndex: 0,
        pageSize: initialTableState.pageSize,
        sortBy: memoSortBy,
        hiddenColumns: memoColumns.filter((col) => !col.isVisible).map(({ id }) => id),
      },
      pageCount,
      totalItemCount,
      pageSizes,
      NoDataComponent,
      apiTable: {
        fetchTableData,
        setUpdating,
        setApiError,
        resetTableState,
      },
      noDataMessage,
    },
    useResizeColumns,
    useFlexLayout,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const {
    gotoPage,
    setPageSize,
    toggleAllRowsSelected,
    state: { pageIndex, pageSize, sortBy },
  } = useTableProps;
  const getTableData = (resetPage) => {
    if (!updating) {
      fetchTableData(resetPage);
    }
  };

  useEffect(() => {
    if (selectAllActive && pageSize === 1000) {
      toggleAllRowsSelected(true);
      setSelectAllActive(false);
    }
  }, [tableData.length]);

  useEffect(() => {
    getTableData();
  }, [pageSize, pageIndex, sortBy, refetch]);

  useEffect(() => {
    if (!loading) {
      if (pageIndex !== 0) {
        gotoPage(0);
      }
      const resetPage = true;
      getTableData(resetPage);
    }
  }, [searchQuery, filters, filterMatch]);

  useEffect(() => {
    if (selectAllActive && pageSize !== 1000) {
      setSelectAllActive(false);
    }
  }, [setPageSize, pageSize, selectAllActive]);

  useEffect(() => {
    const columns = settings?.tables[reduxTableName]?.columns;

    if (_isNotEmpty(columns)) {
      const currentVal = columns['pageSize'];
      columns['pageSize'] = !currentVal;
      const updatedSettings = {
        ...settings,
        tables: {
          ...settings.tables,
          [reduxTableName]: { ...settings.tables[reduxTableName], pageSize },
        },
      };
      saveTableSettings(updatedSettings);
    }
  }, [pageSize]);

  useEffect(() => {
    const columns = settings?.tables[reduxTableName]?.columns;

    if (_isNotEmpty(columns)) {
      const currentVal = columns['queryBy'];
      columns['queryBy'] = !currentVal;
      const updatedSettings = {
        ...settings,
        tables: {
          ...settings.tables,
          [reduxTableName]: { ...settings.tables[reduxTableName], queryBy },
        },
      };
      saveTableSettings(updatedSettings);
    }
  }, [queryBy]);

  const handleSelectAllClick = () => {
    if (selectAllActive) {
      setSelectAllActive(false);
      setPageSize(10);
      toggleAllRowsSelected(false);
    } else {
      setSelectAllActive(true);
      setPageSize(1000);
    }
  };

  let actionBarProps = {
    searchQuery,
    setSearchQuery,
    filterOptions,
    filters,
    setFilters,
    filterMatch,
    setFilterMatch,
    searchPlaceholderText,
    updating,
    searchQueryBy: queryBy,
    setSearchQueryBy: setQueryBy,
    searchQueryByOptions,
    settings,
    showMetricChangeFrom,
    tableName: reduxTableName,
    hideSearch,
    hideFilter,
    hasSelectorColumn,
  };

  if (bulkActions) {
    actionBarProps = {
      ...actionBarProps,
      bulkActions: bulkActions({
        useTableProps,
        tableData,
        fetchTableData,
        setUpdating,
        setApiError,
        resetTableState,
      }),
      selectAllActive,
      handleSelectAllClick,
    };
  }

  const handleExportClick = async (route) => {
    setExporting(true);
    const params = getParams({
      searchQuery,
      filters,
      filterMatch,
      sortBy,
      pageSize,
      pageIndex,
      includeParam,
      columns: columns.map((c) => c.accessor),
    });

    try {
      const { data, headers } = await Http({
        method: 'get',
        url: getExportUrl(route, params),
        responseType: 'blob',
      });
      downloadFile({ data, headers });
      successToast(`Downloaded ${headers['file-name']}`);
    } catch (error) {
      setApiError(error);
    } finally {
      setExporting(false);
    }
  };
  const AsideComponent = !(ExternalAsideComponent instanceof Function)
    ? (ExternalAsideComponent ?? null)
    : ExternalAsideComponent({ useTableProps });

  return (
    <>
      {loading || _isEmpty(columns) ? (
        <div className={loadingClassName}>
          <Loading txt={loadingText} />
        </div>
      ) : (
        <>
          <div
            ref={tableRef}
            className={`ui-card ${updating ? 'ui__disabled' : ''}`}
            data-test-component="APITable"
            data-test-element="api-table-container"
          >
            {apiError && <ApiErrorBanner errorType="fetch" error={apiError} onDismiss={() => setApiError(false)} />}
            <TableProvider useTableProps={{ ...useTableProps }}>
              <div className="pa3 bb b--moon-gray">
                <div className="flex justify-between w-100">
                  <ActionBar {...actionBarProps} />
                  {AsideComponent}
                  {exportRoutes && (
                    <ExportButton
                      routes={exportRoutes}
                      handleExportClick={handleExportClick}
                      exporting={exporting}
                      disabled={_isEmpty(tableData)}
                    />
                  )}
                </div>
                {_isNotEmpty(filters) && (
                  <Filters
                    filters={filters}
                    filterMatch={filterMatch}
                    updateFilters={(filters, filterMatch) => {
                      setFilters(filters);
                      setFilterMatch(filterMatch);
                    }}
                  />
                )}
              </div>
              <TableWithContext tableRef={tableRef} testIdPrefix={testIdPrefix} maxHeight={maxHeight} />
            </TableProvider>
          </div>
          {renderCustomActions && renderCustomActions(useTableProps)}
        </>
      )}
    </>
  );
};

export default APITable;
